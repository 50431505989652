<script setup>
import { useHistory } from "@/composables/useHistory";

const { historyColl } = useHistory();

function handleOpen(history) {
  useEvent("singlebible.openlocation", { location: history.location });
}
</script>
<template>
  <div class="history-list width-100">
    <div>
      <div v-for="history in historyColl" class="list-row" @click="handleOpen(history)">
        <span class="list-bible">{{ history.bibleShortName }}</span>
        <span class="list-book">{{ history.bookName }}</span>
        <span class="">{{ history.chapterNum }}</span>
      </div>
    </div>

    <div v-if="historyColl?.length == 0" class="empty-list">
      {{ transl("Empty list") }}
    </div>
  </div>
</template>
<style scoped>
.history-list {
  font-size: 20px;
  overflow-y: auto;
  padding-bottom: 50px;
}
.list-row {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  /* display: grid;
  grid-template-columns: 1fr 1fr 40px;
  grid-template-columns: minmax(100px, 140px) auto 40px; */
}
.list-bible {
  width: 120px;
}

.list-row:hover {
  background: var(--root-green);
  color: #fff;
  cursor: pointer;
}

.list-bible {
  margin: 0 10px;
  font-weight: 700;
}

.list-book {
  margin: 0 10px;
}

.empty-list {
  display: flex;
  justify-content: center;
}
</style>
